import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { colors } from 'midtype-backend';

import Layout from '../layout/Layout';
import Check from '../icons/Check';
import Cross from '../icons/Cross';
import Bicycle from '../icons/Bicycle';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${colors.GRAY_1()};
  }
`;

const Styled = styled.div`
  padding: 50px;
  margin: auto;
  text-align: center;
  max-width: 80rem;

  .text-container {
    max-width: 50rem;
    margin: auto;
    margin-bottom: 5rem;
  }

  .tiers {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;
    background: ${colors.WHITE()};
    box-shadow: 0 0 30px ${colors.BLACK(0.05)};
    border-radius: 5px;
    border: 1px solid ${colors.GRAY_2(0.5)};
    margin-bottom: 5rem;
  }

  .tiers__divider {
    background: ${colors.GRAY_1()};
    padding: 1rem 2rem;
    border: 1px solid ${colors.GRAY_2(0.5)};
    border-left: none;
    border-right: none;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: left;
  }

  .tier {
    padding: 2rem;
    border-left: 1px solid ${colors.GRAY_2(0.5)};
    text-align: center;
  }
  .tier:first-child {
    border: none;
  }
  .tier--icon {
    overflow: hidden;
    position: relative;
  }
  .tier--icon svg {
    position: absolute;
    left: -25%;
    top: -25%;
    width: 150%;
    height: 150%;
  }
  .tier__name {
    padding: 0.5rem 1rem;
    color: ${colors.GRAY_3()};
    font-size: 0.6rem;
    background: ${colors.GRAY_2(0.5)};
    width: fit-content;
    border-radius: 3px;
    margin: auto;
    margin-bottom: 2rem;
  }
  .tier--Professional .tier__name {
    background: ${colors.PURPLE_DARK(0.05)};
    color: ${colors.PURPLE_DARK()};
  }
  .tier__price {
    margin: 0.5rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .tier__price span {
    color: ${colors.GRAY_3()};
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-right: 0.1rem;
  }
  .tier__divider {
    width: 3rem;
    border: none;
    border-top: 1px solid ${colors.GRAY_2()};
    margin: 2rem auto;
  }
  .tier__includes {
    display: flex;
    flex-flow: column;
    width: fit-content;
    margin: auto;
  }
  .tier__includes li {
    color: ${colors.GRAY_3()};
    margin-bottom: 0.5rem;
    text-align: left;
  }
  .tier__includes li svg {
    fill: green;
    margin-right: 0.5rem;
  }
  .tier__labels__label,
  .tier__limits__limit {
    border-bottom: 1px solid ${colors.GRAY_2()};
    padding: 0 2rem;
    text-align: left;
    height: 4rem;
    display: flex;
    align-items: center;
    &:last-child {
      border: none;
    }
  }
  .tier__labels__label p,
  .tier__limits__limit p {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .tier__limits__limit {
    justify-content: center;
  }
  .tier__limits__limit p {
    font-weight: bold;
    text-align: center;
  }
  .tier__limits__limit svg {
    fill: green;
    width: 1.25rem;
    height: 1.25rem;
  }
  .tier__limits__limit svg.red {
    fill: ${colors.RED(0.4)};
    width: 1rem;
    height: 1rem;
  }
  .tier__limits {
    border-left: 1px solid ${colors.GRAY_2()};
  }
  .footnote {
    max-width: 50rem;
    margin: auto;
    margin-bottom: 1rem;
  }

  .toggle {
    display: none;
    border: 1px solid ${colors.GRAY_2()};
    margin: auto;
    margin-bottom: 2rem;
    width: fit-content;
    border-radius: 5px;
    overflow: hidden;
  }
  .toggle__option {
    padding: 1rem 2rem;
    background: white;
    transition: 250ms all;
    cursor: pointer;
  }
  .toggle__option:first-child {
    border-right: 1px solid ${colors.GRAY_2()};
  }
  .toggle__option h5 {
    color: ${colors.GRAY_3()};
  }
  .toggle__option--active {
    background: ${colors.PURPLE_LIGHT(0.1)} !important;
    box-shadow: inset 0 0 10px ${colors.PURPLE_LIGHT(0.3)};
  }
  .toggle__option--active h5 {
    color: ${colors.PURPLE_LIGHT()};
  }
  .toggle__option:hover {
    background: ${colors.GRAY_1()};
  }

  @media screen and (max-width: 1024px) {
    padding: 30px;
  }
  @media screen and (max-width: 800px) {
    padding: 20px;
    .toggle {
      display: flex;
    }
    .tiers {
      grid-template-columns: 2fr 3fr;
    }
    .tier {
      display: none;
    }
    .tier--icon {
      display: block;
    }
    .tier__limits {
      display: none;
    }
    .tier--active,
    .tier__limits--active {
      display: block;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 10rem;
  }
`;

type Item = string | JSX.Element;

interface ITierProps {
  title: Item;
  price: Item;
  description: Item;
  includes?: Item[];
  active: boolean;
}

const Tier: React.FC<ITierProps> = props => {
  const { title, price, description, active } = props;
  return (
    <div
      className={[
        'tier',
        `tier--${title}`,
        `tier--${active ? 'active' : 'inactive'}`
      ].join(' ')}
    >
      <h5 className="tier__name text-caps">{title}</h5>
      <h3 className="tier__price">{price}</h3>
      <label className="tier__description">{description}</label>
    </div>
  );
};

const TierUsageLabel: React.FC<any> = props => {
  const { label } = props;
  return (
    <div className="tier__labels__label">
      <p className="text-no-margin">{label}</p>
    </div>
  );
};

const TierUsageLimit: React.FC<any> = props => {
  const { label } = props;
  return (
    <div className="tier__limits__limit">
      <p className="text-no-margin">{label}</p>
    </div>
  );
};

const TierUsageSection: React.FC<any> = props => {
  const { title, limits, active } = props;
  return (
    <React.Fragment>
      <div className="tiers__divider">
        <p className="text-no-margin text-small">
          <strong>{title}</strong>
        </p>
      </div>
      <div className="tier__labels">
        {Object.keys(limits).map(label => (
          <TierUsageLabel key={label} label={label} />
        ))}
      </div>
      <div
        className={`tier__limits tier__limits--${
          active === 'basic' ? 'active' : 'inactive'
        }`}
      >
        {Object.keys(limits).map(label => (
          <TierUsageLimit key={label} label={limits[label][0]} />
        ))}
      </div>
      <div
        className={`tier__limits tier__limits--${
          active === 'enterprise' ? 'active' : 'inactive'
        }`}
      >
        {Object.keys(limits).map(label => (
          <TierUsageLimit key={label} label={limits[label][1]} />
        ))}
      </div>
    </React.Fragment>
  );
};

const USAGE: { [key: string]: [string | JSX.Element, string | JSX.Element] } = {
  'Users for Your App': ['Unlimited', 'Unlimited'],
  'Collect App Subscription Fees': ['Unlimited', 'Unlimited'],
  Requests: ['10,000 free (per month) + $0.0005/request', 'Unlimited'],
  Storage: ['1 GB free + $1/GB', 'Unlimited'],
  Developers: ['1', 'Multiple'],
  Environments: ['1', 'Multiple']
};

const PLATFORM: {
  [key: string]: [string | JSX.Element, string | JSX.Element];
} = {
  'User Activity Audit Logs': [<Cross className="red" />, <Check />],
  'Highly Available (Active Replica)': [<Cross className="red" />, <Check />],
  'Managed Data Backups': ['Weekly', 'Daily'],
  'On Demand Data Backups': [<Cross className="red" />, <Check />],
  'Computational Resources†': ['Shared', 'Dedicated'],
  'Spending Alerts & Caps': ['Coming Soon', 'Not Applicable'],
  'Full Data Export*': [<Cross className="red" />, <Check />]
};

const Pricing: React.FC = () => {
  const [active, setActive] = useState('basic');

  return (
    <Layout pageTitle="Pricing">
      <Styled>
        <div className="text-container">
          <h1 style={{ marginBottom: 10 }}>Free to Get Started</h1>
          <p className="large section-description">
            Start building with a generous helping of Midtype&#8217;s services
            for free, <strong>no credit card required</strong>. Move up to more
            advanced features with enterprise-grade reliability when you're
            ready.
          </p>
        </div>
        <div className="toggle">
          <div
            onClick={() => setActive('basic')}
            className={`toggle__option toggle__option--${
              active === 'basic' ? 'active' : 'inactive'
            }`}
          >
            <h5>Basic</h5>
          </div>
          <div
            onClick={() => setActive('enterprise')}
            className={`toggle__option toggle__option--${
              active === 'enterprise' ? 'active' : 'inactive'
            }`}
          >
            <h5>Professional</h5>
          </div>
        </div>
        <div className="tiers">
          <div className="tier tier--icon">
            <Bicycle />
          </div>
          <Tier
            title="Basic"
            active={active === 'basic'}
            price={
              <React.Fragment>
                <span>$</span>0
              </React.Fragment>
            }
            description="Per Month + Pay-Per-Use Charges"
          />
          <Tier
            title="Professional"
            active={active === 'enterprise'}
            price="Contact Us"
            description="Enterprise Security & Reliability"
          />

          <TierUsageSection
            title="Product Usage Limits"
            active={active}
            limits={USAGE}
          />
          <TierUsageSection
            title="Platform Features"
            active={active}
            limits={PLATFORM}
          />
        </div>
        <p className="footnote">
          †&nbsp;Shared resources sleep when not in use. First request after a
          period of inactivity can take up to 5 seconds to warm up. Professional
          plan is recommended for serious production use.
        </p>
        <p className="footnote">
          *&nbsp;In case you choose to leave the Midtype platform, we offer a
          full export of your data.
        </p>
      </Styled>
      <GlobalStyle />
    </Layout>
  );
};

export const query = graphql`
  query GetStripePlans {
    allStripePlan {
      nodes {
        id
        slug
        amount
      }
    }
  }
`;

export default Pricing;
